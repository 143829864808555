import LanguageModel from '@Api/models/LanguageModel';
import { TypingMode } from '@Api/models/TypingTestResultModel';
import UserModel from '@Api/models/UserModel';

import { AntiCheatCheckResponse } from '@Api-generated';

export default class AntiCheatCheckModel implements AntiCheatCheckResponse {
  public id: string;
  public createdAt: string;
  public typingMode: TypingMode;
  public maxReachedWpm: number;
  public user: UserModel;
  public language: LanguageModel;

  constructor(
    id: string,
    createdAt: string,
    typingMode: TypingMode,
    maxReachedWpm: number,
    user: UserModel,
    language: LanguageModel
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.typingMode = typingMode;
    this.maxReachedWpm = maxReachedWpm;
    this.user = user;
    this.language = language;
  }

  public static hydrateFromApi(antiCheatCheckResponse: AntiCheatCheckResponse) {
    const userModel = UserModel.hydrateFromApi(antiCheatCheckResponse.user);

    const languageModel = LanguageModel.hydrateFromApi(antiCheatCheckResponse.language);

    const typingMode = antiCheatCheckResponse.typingMode as TypingMode;

    return new AntiCheatCheckModel(
      antiCheatCheckResponse.id,
      antiCheatCheckResponse.createdAt,
      typingMode,
      antiCheatCheckResponse.maxReachedWpm,
      userModel,
      languageModel
    );
  }
}
