import React, { MouseEvent, ReactNode } from 'react';

import { PropsWithClassName } from '@Components/helper';

import { CloseIcon, Content, ContentWindow, Root, StyledIconX } from './Modal.styles';

interface Props extends PropsWithClassName {
  minWidth?: number;
  minHeight?: number;
  onClose?: () => void;
  headline?: string;
  children: ReactNode;
  disableBackdropClick?: boolean;
}

export const Modal = (props: Props): React.ReactElement => {
  const { className, minWidth, minHeight, children, onClose, disableBackdropClick } = props;

  const backdropRef = React.createRef<HTMLDivElement>();

  const handleClose = () => {
    if (!onClose) {
      return;
    }

    onClose();
  };

  const handleBackdropClick = (ev: MouseEvent) => {
    if (ev.nativeEvent.target !== backdropRef.current || disableBackdropClick) {
      return;
    }

    handleClose();
  };

  return (
    <Root
      ref={backdropRef}
      className={className}
      data-testid={props.dataTestId ? props.dataTestId : 'Modal-root'}
      onClick={handleBackdropClick}
    >
      <ContentWindow minWidth={minWidth ?? 800} minHeight={minHeight}>
        <CloseIcon>
          <StyledIconX size={30} onClick={handleClose} />
        </CloseIcon>
        <Content>{children}</Content>
      </ContentWindow>
    </Root>
  );
};
