import React, { useEffect, useState } from 'react';

import { PropsWithClassName } from '@Components/helper';

import { Count, Root, TimerLt, TimerRt, TimerSlot } from './CountdownSpinner.styles';

interface Props extends PropsWithClassName {
  initialCount: number;
  timerStartCount?: number;
  onFinish: () => void;
  loading: boolean;
}

const CountdownSpinner = (props: Props): React.ReactElement => {
  const { initialCount, onFinish, loading, timerStartCount } = props;

  const [count, setCount] = useState<number>(timerStartCount ?? initialCount);

  useEffect(() => {
    const interval = setInterval(() => {
      if (loading) {
        return;
      }

      const newCount = count - 1;
      if (newCount <= 0) {
        onFinish();
        return;
      }
      setCount(newCount);
    }, 1000);

    return () => clearInterval(interval);
  }, [count, onFinish, loading]);

  const renderContent = () => {
    if (loading) {
      return <></>; // todo: spinner while loading?
    }

    return (
      <>
        <TimerSlot>
          <TimerLt
            duration={initialCount}
            initialProgress={timerStartCount ? initialCount - timerStartCount : 0}
          ></TimerLt>
        </TimerSlot>
        <TimerSlot>
          <TimerRt
            duration={initialCount}
            initialProgress={timerStartCount ? initialCount - timerStartCount : 0}
          ></TimerRt>
        </TimerSlot>
        <Count>{count}</Count>
      </>
    );
  };

  return (
    <Root className={props.className} data-testid={props.dataTestId ?? 'CountdownSpinner-root'}>
      {renderContent()}
    </Root>
  );
};

export { CountdownSpinner };
