import styled, { keyframes } from 'styled-components';

const timerSlideLt = keyframes`
  0% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(225deg);
  }
`;

const timerSlideRt = keyframes`
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(135deg);
  }
`;

export interface TimerProps {
  duration: number;
  initialProgress: number;
}

export const Root = styled.div`
  width: 12.5rem;
  height: 12.5rem;
  margin: ${(props) => props.theme.space.large_32} auto;
  position: relative;
  cursor: pointer;
`;

export const TimerSlot = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  float: left;
`;

export const TimerLt = styled.div<TimerProps>`
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 1%;
  border: 10px solid ${(props) => props.theme.palette.spinner.light};
  width: 200%;
  height: 100%;
  border-right-color: transparent;
  border-top-color: transparent;
  z-index: 5;

  animation: ${(props) => props.duration}s linear forwards ${timerSlideLt} -${(props) => props.initialProgress}s;
`;

export const TimerRt = styled.div<TimerProps>`
  border-radius: 50%;
  position: relative;
  top: 0;
  left: -101%;
  border: 10px solid ${(props) => props.theme.palette.spinner.light};
  width: 200%;
  height: 100%;
  border-top-color: transparent;
  border-left-color: transparent;
  z-index: 5;

  animation: ${(props) => props.duration}s linear forwards ${timerSlideRt} -${(props) => props.initialProgress}s;
`;

export const Count = styled.div`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  font-size: 8rem;
  text-align: center;
  vertical-align: center;
  font-family: ${(props) => props.theme.font.primaryFamily};

  font-family: ${(props) => props.theme.font.primaryFamily};
  font-weight: ${(props) => props.theme.font.text.semibold.medium.fontWeight};
  color: ${(props) => props.theme.palette.spinner.default};
  letter-spacing: ${(props) => props.theme.font.text.light.medium.letterSpacing};
`;
