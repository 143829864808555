import { utcToZonedTime } from 'date-fns-tz';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { getBrowserTimezone } from '@Components/helper/date';
import { capitalizeFirstLetter } from '@Components/helper/language';
import Modal from '@Components/molecules/Modal';
import AntiCheatTypingBox from '@Components/organisms/AntiCheatTypingBox/AntiCheatTypingBox';

import {
  AntiCheatGameResult,
  getLanguageFromAntiCheatGameResult,
  getTypingModeTranslationFromAntiCheatGameResult,
} from '@Helpers/result';
import { TextColor, TextSize } from '@Helpers/types/text';

import { StyledButton, StyledHeadline, StyledText, TextContainer } from './AntiCheatModal.styles';

interface Props extends PropsWithClassName {
  result: AntiCheatGameResult;
  onClose: () => void;
  onSuccess: () => void;
}

enum AntiCheatModalState {
  Start = 'start',
  Failure = 'failure',
  Success = 'success',
  AlreadyUnlocked = 'alreadyUnlocked',
  Expired = 'expired',
}

const AntiCheatModal = (props: Props): React.ReactElement => {
  const { t } = useTranslation('global');

  const [showAntiCheatBox, setShowAntiCheatBox] = useState<boolean>(false);
  const [reachedWpm, setReachedWpm] = useState<number>(0);
  const [modalState, setModalState] = useState<AntiCheatModalState>(AntiCheatModalState.Start);

  const { result, onClose, onSuccess } = props;

  const height = 400;
  const width = 800;

  const languageName = capitalizeFirstLetter(getLanguageFromAntiCheatGameResult(result).languageNative);
  const typingMode = getTypingModeTranslationFromAntiCheatGameResult(result, t);

  useEffect(() => {
    //todo: delete notifications for unlocked and too old typing tests to prevent this states?
    if (result.isLocked === false) {
      setModalState(AntiCheatModalState.AlreadyUnlocked);
      return;
    }

    const createdAtWithTimeZone = utcToZonedTime(result.createdAt, getBrowserTimezone());
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (createdAtWithTimeZone.getTime() < yesterday.getTime()) {
      setModalState(AntiCheatModalState.Expired);
    }
  }, [result]);

  const renderContent = (): React.ReactElement => {
    if (showAntiCheatBox) {
      return (
        <AntiCheatTypingBox
          result={result}
          maxHeight={height}
          maxWidth={width}
          onReset={() => {
            setShowAntiCheatBox(false);
            setModalState(AntiCheatModalState.Start);
          }}
          onSuccess={(reachedWpm: number) => {
            setShowAntiCheatBox(false);
            setModalState(AntiCheatModalState.Success);
            setReachedWpm(reachedWpm);
          }}
          onFailure={() => {
            setShowAntiCheatBox(false);
            setModalState(AntiCheatModalState.Failure);
          }}
        />
      );
    }

    switch (modalState) {
      case AntiCheatModalState.Start:
        return (
          <>
            <StyledText size={TextSize.Medium}>{t('anti_cheat.introduction')}</StyledText>
            <StyledText size={TextSize.Medium}>{t('anti_cheat.description')}</StyledText>
            <StyledText size={TextSize.Medium}>{t('anti_cheat.explanation')}</StyledText>
            <StyledButton
              modifier={ButtonModifier.Primary}
              type={ButtonType.Button}
              onClick={() => setShowAntiCheatBox(true)}
            >
              {t('anti_cheat.button.start')}
            </StyledButton>
          </>
        );
      case AntiCheatModalState.Failure:
        return (
          <>
            <StyledText size={TextSize.Medium}>{t('anti_cheat.fail_message')}</StyledText>
            <StyledButton
              modifier={ButtonModifier.Primary}
              type={ButtonType.Button}
              onClick={() => setShowAntiCheatBox(true)}
            >
              {t('anti_cheat.button.failure')}
            </StyledButton>
          </>
        );
      case AntiCheatModalState.Success:
        return (
          <>
            <StyledText size={TextSize.Medium}>{t('anti_cheat.success_message', { reachedWpm })}</StyledText>
            <StyledButton modifier={ButtonModifier.Primary} type={ButtonType.Button} onClick={onSuccess}>
              {t('anti_cheat.button.success')}
            </StyledButton>
          </>
        );
      case AntiCheatModalState.AlreadyUnlocked:
        return (
          <>
            <StyledText size={TextSize.Medium}>{t('anti_cheat.already_unlocked_message')}</StyledText>
            <StyledButton modifier={ButtonModifier.Primary} type={ButtonType.Button} onClick={onClose}>
              {t('anti_cheat.button.already_unlocked')}
            </StyledButton>
          </>
        );
      case AntiCheatModalState.Expired:
        return (
          <>
            <StyledText size={TextSize.Medium}>{t('anti_cheat.expired_message')}</StyledText>
            <StyledButton modifier={ButtonModifier.Primary} type={ButtonType.Button} onClick={onClose}>
              {t('anti_cheat.button.expired')}
            </StyledButton>
          </>
        );
    }
  };

  return (
    <Modal
      disableBackdropClick
      onClose={modalState === AntiCheatModalState.Success ? onSuccess : onClose}
      minWidth={width}
      className={props.className}
      dataTestId={props.dataTestId ?? 'AntiCheatModal-root'}
    >
      <TextContainer>
        <StyledHeadline importance={2}>{t('anti_cheat.modal.headline')}</StyledHeadline>
        <Text size={TextSize.Xs} color={TextColor.Tertiary}>
          {result.testResult.wpm} {t('units.wpm.short', { ns: 'global' })}, {languageName}, {typingMode},{' '}
          {t('anti_cheat.result.score')}: {result.testResult.score}, {result.testResult.correctKeystrokes}{' '}
          {t('anti_cheat.result.correct_keystrokes')}, {result.testResult.wrongKeystrokes}{' '}
          {t('anti_cheat.result.wrong_keystrokes')}
        </Text>
      </TextContainer>
      {renderContent()}
    </Modal>
  );
};

export default AntiCheatModal;
