import { Options } from '@hey-api/client-fetch';

import {
  AntiCheatCheckResponse,
  completeAntiCheatCheck,
  type CompleteAntiCheatCheckData,
  getAntiCheatImage,
  type GetAntiCheatImageData,
} from '@Root/src/api-generated';

import { ApiError } from '@Api/ApiError';
import AntiCheatCheckModel from '@Api/models/AntiCheatCheckModel';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';
import { useApi } from '@Api/useApi';

const hydrateAntiCheatImage = (response: Blob) => {
  return response;
};

const hydrateAntiCheatCheck = (response: AntiCheatCheckResponse) => {
  return AntiCheatCheckModel.hydrateFromApi(response);
};

export function useGetAntiCheatImage(
  onSuccess?: (result: Blob) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<GetAntiCheatImageData>, Blob, Blob>(
    getAntiCheatImage,
    hydrateAntiCheatImage,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useCompleteAntiCheatCheck(
  onSuccess?: (result: AntiCheatCheckModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<CompleteAntiCheatCheckData>, AntiCheatCheckModel, AntiCheatCheckResponse>(
    completeAntiCheatCheck,
    hydrateAntiCheatCheck,
    onSuccess,
    onError,
    onValidationError
  );
}
