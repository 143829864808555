import { IconRefresh } from '@tabler/icons-react';
import styled from 'styled-components';

import Box from '@Components/atoms/Box';
import Button from '@Components/atoms/Button';

interface StyledImageProps {
  maxWidth: number;
  maxHeight: number;
}

export const Root = styled.div``;

export const InputBox = styled(Box)`
  padding: 0;
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const Input = styled.div`
  padding: ${(props) => props.theme.space.default_16};
  border-radius: 0 0 ${(props) => props.theme.borderRadius.box} ${(props) => props.theme.borderRadius.box};
  border-top: 1px solid ${(props) => props.theme.palette.box.border.default};
  background: ${(props) => props.theme.palette.box.background.secondary};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    border ${(props) => props.theme.transition.theme};
`;

export const InputWithControlsBorder = styled.div`
  position: relative;
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.palette.box.border.gradient.start} 0%,
    ${(props) => props.theme.palette.box.border.gradient.end} 100%
  );
  border-radius: ${(props) => props.theme.borderRadius.box};
  padding: 2px;
`;

export const InputWithControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  background: ${(props) => props.theme.palette.box.background.primary};
  transition: background-color ${(props) => props.theme.transition.theme};
`;
export const StyledInputText = styled.input`
  margin-bottom: 0;
  flex: 2;
  padding: ${(props) => props.theme.space.default_16};
  border: 0;
  outline: none;
  font-size: ${(props) => props.theme.font.text.light.large.fontSize};
  font-weight: ${(props) => props.theme.font.text.light.large.fontWeight};
  line-height: ${(props) => props.theme.font.text.light.large.lineHeight};
  background: ${(props) => props.theme.palette.box.background.primary};
  color: ${(props) => props.theme.palette.text.primary};
  transition:
    background-color ${(props) => props.theme.transition.theme},
    color ${(props) => props.theme.transition.theme};

  &::placeholder {
    font-size: ${(props) => props.theme.font.text.light.large.fontSize};
    font-weight: ${(props) => props.theme.font.text.light.large.fontWeight};
    line-height: ${(props) => props.theme.font.text.light.large.lineHeight};
    color: ${(props) => props.theme.palette.text.tertiary};
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    border: 0;
    outline: none;
  }
`;
export const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${(props) => props.theme.space.default_16};
  height: 55px;
  border-left: 1px solid ${(props) => props.theme.palette.box.border.default};
  transition: border ${(props) => props.theme.transition.theme};
`;

export const Refresh = styled.div`
  position: relative;

  &::before {
    position: absolute;
    top: -3px;
    left: -3px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    content: ' ';
    background: ${(props) => props.theme.palette.icon.background};
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`;

export const StyledIconRefresh = styled(IconRefresh)`
  border: none;
  cursor: pointer;
  position: relative;
  stroke: ${(props) => props.theme.palette.text.tertiary};
`;

export const StyledImage = styled.img<StyledImageProps>`
  max-width: ${(props) => props.maxWidth}px;
  max-height: ${(props) => props.maxHeight}px;

  @media (max-width: ${(props) => props.theme.device.mobile}) {
    max-width: 100%;
  }
`;

export const CountdownWrapper = styled.div`
  padding: ${(props) => props.theme.space.xl_64};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 0;
`;
