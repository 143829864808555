import styled from 'styled-components';

import Button from '@Components/atoms/Button';
import Headline from '@Components/atoms/Headline';
import Text from '@Components/atoms/Text';

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${(props) => props.theme.space.small_8};
`;

export const TextContainer = styled.div`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const StyledText = styled(Text)`
  margin-bottom: ${(props) => props.theme.space.default_16};
`;

export const StyledButton = styled(Button)`
  margin-bottom: 0;
`;
