import { TFunction } from 'i18next';

import CompetitionResultModel from '@Api/models/CompetitionResultModel';
import TypingTestResultModel, { TypingMode } from '@Api/models/TypingTestResultModel';

export type AntiCheatGameResult = TypingTestResultModel | CompetitionResultModel;

export const getLanguageFromAntiCheatGameResult = (result: AntiCheatGameResult) => {
  if (result instanceof CompetitionResultModel) {
    if (!result.competition.language) {
      throw Error('Unexpected Error: Competition language is null');
    }

    return result.competition.language;
  }

  return result.language;
};

export const getTypingModeTranslationFromAntiCheatGameResult = (
  result: AntiCheatGameResult,
  t: TFunction<'global', undefined>
) => {
  if (result instanceof CompetitionResultModel) {
    return t('mode.competition');
  }

  if (result.typingMode === TypingMode.Advanced) {
    return t('typing_test_mode.advanced');
  }

  return t('typing_test_mode.normal');
};
