import { IconX } from '@tabler/icons-react';
import styled from 'styled-components';

interface DivProps {
  minWidth: number;
  minHeight?: number;
}

export const Root = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${(props) => props.theme.palette.static.modalBackdrop};
  display: flex;
  z-index: 10000;
`;

export const ContentWindow = styled.div<DivProps>`
  background: ${(props) => props.theme.palette.background.mainGradient.start};
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.palette.background.mainGradient.start} 0%,
    ${(props) => props.theme.palette.background.mainGradient.end} 50%
  );

  border-radius: ${(props) => props.theme.borderRadius.box};
  width: 20%;
  margin: auto;
  display: flex;
  flex-direction: column;
  min-width: ${(props) => `${props.minWidth}px`};
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : 'auto')};

  @media (max-width: ${(props) => `${props.minWidth}px`}) {
    min-width: 100%;
  }

  @media (max-width: ${(props) => props.theme.device.mobile}) {
    min-width: 100%;
  }
`;

export const Content = styled.div`
  padding-left: ${(props) => props.theme.space.large_32};
  padding-right: ${(props) => props.theme.space.large_32};
  padding-bottom: ${(props) => props.theme.space.large_32};

  @media (max-width: 600px) {
    padding: ${(props) => props.theme.space.small_8};
  }
`;

export const CloseIcon = styled.div`
  display: flex;
  justify-content: end;
  padding-top: ${(props) => props.theme.space.default_16};
  padding-right: ${(props) => props.theme.space.default_16};
`;

export const StyledIconX = styled(IconX)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.primary};
`;
